import React from 'react'
import { graphql } from 'gatsby'
import intl from 'react-intl-universal'
import { SolutionBanner, InviteBanner } from '../../components/banners'
import { CommonTitle } from '../../components/titles'
import { Swiper, H4, Wrapper, Billboard } from '@raysync/common/components'
import { LOCALES } from '@raysync/common/services'
import Layout from '../../components/layout'

import {
  SectionDesc,
  FeatureSection,
  Section,
  SolutionDivider,
  ApplicationSection,
  ApplicationContainer,
  MultiApplicationItem,
  ArchSection,
} from './atoms'
import List from '../../components/list'

import Feature1 from '@raysync/common/components/svg/multi-data-feature1'
import Feature2 from '@raysync/common/components/svg/multi-data-feature2'
import Feature3 from '@raysync/common/components/svg/multi-data-feature3'
import Feature4 from '@raysync/common/components/svg/multi-data-feature4'

import Arch from './images/zh/data-distribution.svg'
import ArchEn from './images/en/data-distribution.svg'
import Banner from '@raysync/common/components/svg/solutions/applications/multi-data-banner'

class MultiTransfer extends React.PureComponent {
  state = {
    featureList: [
      {
        title: intl.get('solution2.feature.item1.title'),
        desc: intl.get('solution2.feature.item1.desc'),
        svg: <Feature1 />,
      },
      {
        title: intl.get('solution2.feature.item2.title'),
        desc: intl.get('solution2.feature.item2.desc'),
        svg: <Feature2 />,
      },
      {
        title: intl.get('solution2.feature.item3.title'),
        desc: intl.get('solution2.feature.item3.desc'),
        svg: <Feature3 />,
      },
      {
        title: intl.get('solution2.feature.item4.title'),
        desc: intl.get('solution2.feature.item4.desc'),
        svg: <Feature4 />,
      },
    ],
  }
  render() {
    const { location, data } = this.props
    const { carBanner, adBanner, internetBanner, moiveBanner } = data
    const { featureList } = this.state
    const ArchPic = {
      [LOCALES.ZH]: <img src={Arch} alt={`跨国数据传输`} />,
      [LOCALES.EN]: <img src={ArchEn} alt={`multi transfer`} />,
      [LOCALES.JA]: <img src={ArchEn} alt={`multi transfer`} />,
    }[intl.options.currentLocale]
    return (
      <Layout pageType='solution2' location={location}>
        <Swiper>
          <SolutionBanner type='solution2' pic={<Banner />} />
        </Swiper>
        <Section>
          <CommonTitle>{intl.get('solution2.section.title')}</CommonTitle>
          <SectionDesc top={25} bottom={18}>
            {intl.get('solution2.section.desc1')}
          </SectionDesc>
          <SectionDesc>{intl.get('solution2.section.desc2')}</SectionDesc>
          <SectionDesc>{intl.get('solution2.section.desc3')}</SectionDesc>
          <SectionDesc bottom={48}>{intl.get('solution2.section.desc4')}</SectionDesc>
          <SolutionDivider />
        </Section>
        <FeatureSection>
          <CommonTitle>{intl.get('solution2.feature.title')}</CommonTitle>
          <SectionDesc top={12} bottom={26}>
            {intl.get('solution2.feature.desc')}
          </SectionDesc>
          <List data={featureList} />
        </FeatureSection>
        <ArchSection>
          <Wrapper>
            <CommonTitle>{intl.get('solution2.arch.title')}</CommonTitle>
            <div>{ArchPic}</div>
          </Wrapper>
        </ArchSection>
        <ApplicationSection>
          <CommonTitle>{intl.get('solution2.scenes.title')}</CommonTitle>
          <ApplicationContainer>
            <MultiApplicationItem>
              <div>
                <Billboard fluid={adBanner.childImageSharp.fluid} />
              </div>
              <H4>{intl.get('solution2.scenes.point1')}</H4>
            </MultiApplicationItem>
            <MultiApplicationItem>
              <div>
                <Billboard fluid={internetBanner.childImageSharp.fluid} />
              </div>
              <H4>{intl.get('solution2.scenes.point2')}</H4>
            </MultiApplicationItem>
            <MultiApplicationItem>
              <div>
                <Billboard fluid={moiveBanner.childImageSharp.fluid} />
              </div>
              <H4>{intl.get('solution2.scenes.point3')}</H4>
            </MultiApplicationItem>
            <MultiApplicationItem>
              <div>
                <Billboard fluid={carBanner.childImageSharp.fluid} />
              </div>
              <H4>{intl.get('solution2.scenes.point4')}</H4>
            </MultiApplicationItem>
          </ApplicationContainer>
        </ApplicationSection>
        <Swiper>
          <InviteBanner type='solution2' variant='inquire' />
        </Swiper>
      </Layout>
    )
  }
}

export default MultiTransfer

export const query = graphql`
  query {
    adBanner: file(relativePath: { regex: "/industry/ad_banner/" }) {
      ...fluidImage
    }
    carBanner: file(relativePath: { regex: "/industry/car_banner/" }) {
      ...fluidImage
    }
    internetBanner: file(relativePath: { regex: "/industry/internet_banner/" }) {
      ...fluidImage
    }
    moiveBanner: file(relativePath: { regex: "/industry/moive_banner/" }) {
      ...fluidImage
    }
  }
`
