import React from 'react'

const SvgMultiDataFeature1 = props => (
  <svg id='multiData_feature1_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.multiData_feature1_svg__st0{fill:none;stroke:#334966;stroke-width:2}'}</style>
    <g id='multiData_feature1_svg__\u5B89\u5168\u7684\u7F51\u7EDC-48x48'>
      <g id='multiData_feature1_svg__Group_3_'>
        <circle id='multiData_feature1_svg__Oval-25' className='multiData_feature1_svg__st0' cx={25} cy={25} r={23} />
        <ellipse
          id='multiData_feature1_svg__Oval-26'
          className='multiData_feature1_svg__st0'
          cx={25}
          cy={25}
          rx={11}
          ry={23}
        />
        <ellipse id='multiData_feature1_svg__Oval-26-Copy' cx={25} cy={25} rx={17} ry={6} fill='#fd5c1f' />
        <ellipse
          id='multiData_feature1_svg__Oval-26_5_'
          className='multiData_feature1_svg__st0'
          cx={25}
          cy={25}
          rx={11}
          ry={23}
        />
        <ellipse
          id='multiData_feature1_svg__Oval-26-Copy_5_'
          className='multiData_feature1_svg__st0'
          cx={25}
          cy={25}
          rx={23}
          ry={11}
        />
      </g>
    </g>
  </svg>
)

export default SvgMultiDataFeature1
