import React from 'react'

const SvgMultiDataFeature4 = props => (
  <svg id='multiData_feature4_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.multiData_feature4_svg__st0{fill:#334966}'}</style>
    <g id='multiData_feature4_svg__\u63A5\u5165\u5B89\u5168\u7B80\u5355-48x48'>
      <path
        id='multiData_feature4_svg__Fill-6-Copy'
        className='multiData_feature4_svg__st0'
        d='M25 1l-2.18 1.19A49.692 49.692 0 014 8v21.53c0 5.21 2.89 9.99 7.5 12.4L25 49l13.5-7.07c4.61-2.42 7.5-7.19 7.5-12.4V8c-6.6-.66-13-2.64-18.82-5.81L25 1zm0 2.28l1.22.66A51.278 51.278 0 0044 9.78v19.75c0 4.48-2.47 8.55-6.43 10.63L25 46.74l-12.57-6.58A11.979 11.979 0 016 29.53V9.78a51.07 51.07 0 0017.78-5.84L25 3.28z'
      />
      <path
        id='multiData_feature4_svg__Combined-Shape_35_'
        className='multiData_feature4_svg__st0'
        d='M28 19v-4l4 4 2 2H12v-2h16z'
      />
      <path
        id='multiData_feature4_svg__Combined-Shape-Copy_1_'
        className='multiData_feature4_svg__st0'
        d='M22 31v4l-4-4-2-2h22v2H22z'
      />
      <path id='multiData_feature4_svg__Rectangle-81-Copy' fill='#fd5c1f' d='M12 21h26v8H12z' />
    </g>
  </svg>
)

export default SvgMultiDataFeature4
